import { useQuery } from "@tanstack/react-query";
import { api } from "~/services/apiClient";
interface Section {
	secure_id: string;
	title: string;
	slug: string;
	tags: string;
	order: number;
	created_at: string;
	updated_at: string;
}
interface ShelfCategoryProps {
	secure_id: string;
	title: string;
	slug: string;
	source: string;
	tags: string;
	created_at: string;
	updated_at: string;
	section: Section;
}

type GetShelfCategoriesResponse = {
	categories: ShelfCategoryProps[];
};

type GetShelfCategoriesProps = {
	search?: string;
	sectionSecureId?: string;
};

export async function getShelfCategories({
	search,
	sectionSecureId
}: GetShelfCategoriesProps): Promise<GetShelfCategoriesResponse> {
	const response = await api.get("/v1/solucao-sortimento-categories", {
		params: {
			search,
			sectionSecureId
		},
	});

	return {
		categories: response.data,
	};
}

export function useShelfCategories({ search, sectionSecureId }: GetShelfCategoriesProps) {
	return useQuery(["shelfCategorie", search, sectionSecureId], () =>
		getShelfCategories({ search, sectionSecureId })
	);
}
