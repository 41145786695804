export const removeSlot = function () {
	const { googletag } = window
	googletag.cmd.push(function () {
		googletag.destroySlots()
	})
}

export const defineSlot = function (name, id, sizes = [], params = []) {
	if (window) {
		const { googletag, lotaudsList } = window

		if (googletag) {
			const render = () => {
				googletag.defineSlot(name, sizes, id).addService(googletag.pubads());
				googletag.pubads().setTargeting('lotauds', lotaudsList)
				for (const param of params) {
					googletag.pubads().setTargeting('local', param)
				}
				googletag.pubads().enableSingleRequest()
				googletag.pubads().collapseEmptyDivs()
				googletag.enableServices()
				googletag.display(id)
			}

			googletag.cmd.push(function () {
				try {
					render();
				} catch (e) {
					removeSlot();
					render();
				}
			})
		}
	}
}
