import { Image, Text, TextProps } from "@chakra-ui/react";

interface TitleProps extends TextProps {
	text: string
	heightImage?: string | number
}

export function Title({ text, heightImage, ...rest }: TitleProps) {
	return (
		<Text
			textTransform="uppercase"
			width="100%"
			fontSize="2rem"
			fontWeight="bold"
			lineHeight="normal"
			color="#000"
			display="flex"
			{...rest}
		>
			<Image src="/iconTitles.png" alt="Ícone" height={heightImage ?? "22px"} marginRight="3" /> {text}
		</Text>
	)
}
