import { CSSProperties, useEffect, useState } from "react";

type AdSlotProps = {
	id: string;
	customStyle?: CSSProperties
}

export function AdSlot({ id, customStyle }: AdSlotProps) {
	const [adLoaded, setAdLoaded] = useState(false);

	useEffect(() => {
		//@ts-ignore
		const { googletag } = window;

		const handleSlotRenderEnded = (event: any) => {
			if (event.slot.getSlotElementId() === id) {
				if (event.isEmpty) {
					setAdLoaded(false); // Se o anúncio não for carregado
				} else {
					setAdLoaded(true); // Se o anúncio for carregado
				}
			}
		};

		if (typeof window !== 'undefined' && googletag) {
			googletag.cmd.push(function () {
				googletag.pubads().addEventListener('slotRenderEnded', handleSlotRenderEnded);
			});
		}

		// Remover apenas o listener específico ao desmontar o componente
		return () => {
			if (googletag) {
				googletag.cmd.push(function () {
					googletag.pubads().removeEventListener('slotRenderEnded', handleSlotRenderEnded);
				});
			}
		};
	}, [id]);

	return (
		<div
			id={id}
			data-ad-client={id}
			style={!!adLoaded ? customStyle : {}}
		/>
	)
}
