import { Box, Icon } from "@chakra-ui/react"
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md"

export function Arrow(props: {
	disabled: boolean
	left?: boolean
	onClick: (e: any) => void
}) {
	return (
		<Box
			width="30px"
			height="30px"
			position="absolute"
			top="50%"
			transform="translateY(-50%)"
			background="#D1D1D1"
			rounded="full"
			fill="#FFF"
			justifyContent="center"
			alignItems="center"
			display="flex"
			cursor="pointer"
			onClick={props.onClick}
			left={props.left ? '5' : undefined}
			right={props.left ? undefined : '5'}
		>
			{props.left && (
				<Icon as={MdKeyboardArrowLeft} fontSize="2xl" />
			)}
			{!props.left && (
				<Icon as={MdKeyboardArrowRight} fontSize="2xl" />)}
		</Box>
	)
}
