import { Flex, Image, Tag, TagLabel, Text, VStack } from "@chakra-ui/react";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import Link from "next/link";
import { useState } from "react";
import { PostHomeProps } from "~/utils/Types/Home";
import { Arrow } from "./ArrowSlideShelf";
import FormatPortalLinks from "~/utils/Functions/FormatPortalLinks";

type SlideShelfProps = {
	shelf: PostHomeProps[];
};

export function SlideShelf({ shelf }: SlideShelfProps) {
	const [currentSlide, setCurrentSlide] = useState(0);
	const [loaded, setLoaded] = useState(false);
	const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
		initial: 0,
		slideChanged(slider) {
			setCurrentSlide(slider.track.details.rel);
		},
		created() {
			setLoaded(true);
		},
	});

	return (
		<Flex position="relative" w="100%" maxW={{ sm: "382px", lg: "400px" }}>
			<Flex
				ref={sliderRef}
				className="keen-slider"
				rounded="lg"
				height="480px"
				shadow="2xl"
				width="100%"
				background="#FFF"
			>
				{shelf.map((item) => (
					<Flex
						key={item.secure_id}
						className="keen-slider__slide"
						align="center"
						justify="center"
						height="480px"
						maxWidth="400px"
					>
						<VStack
							as={Link}
							href={FormatPortalLinks.formatPostLink({ post: item })}
						>
							<Text
								pos="absolute"
								top="50px"
								textStyle="headerMD"
								fontSize="xx-large"
								px="2"
								as="h3"
								textAlign="center"
								sx={{
									"@media (max-width: 800px)": {
										textOverflow: "ellipsis",
										overflow: "hidden",
										"-webkit-line-clamp": "3",
										display: "-webkit-box",
										"-webkit-box-orient": "vertical",
										maxWidth: 300
									},
								}}
							>
								{item.title}
							</Text>
							{item.is_sponsored && (
								<Flex position="absolute" top="14px" right="14px" gap="3">
									<Tag
										zIndex="2"
										size="sm"
										padding="0 0,5rem"
										borderRadius="full"
										variant="solid"
										background="#0360D7"
										width="min-content"
									>
										<TagLabel mb="-0.15rem">Patrocinado</TagLabel>
									</Tag>
								</Flex>
							)}

							<Image
								height="200px"
								width="200px"
								objectFit="cover"
								src={item.thumbnail?.url}
								alt={item.thumbnail?.alt_text ?? item.title}
							/>
						</VStack>
					</Flex>
				))}
				<Text
					as={Link}
					href="/prateleiras"
					position="absolute"
					bottom="20px"
					right="40px"
					color="#895864"
					textStyle="headerMD"
					textDecor="underline"
				>
					VER TODAS
				</Text>
			</Flex>
			{loaded && instanceRef.current && (
				<>
					<Arrow
						left
						onClick={(e: any) =>
							e.stopPropagation() || instanceRef.current?.prev()
						}
						disabled={currentSlide === 0}
					/>
					<Arrow
						onClick={(e: any) =>
							e.stopPropagation() || instanceRef.current?.next()
						}
						disabled={
							currentSlide ===
							instanceRef.current.track.details.slides.length - 1
						}
					/>
				</>
			)}
		</Flex>
	);
}
