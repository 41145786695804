import {
	FormControl,
	FormErrorMessage,
	FormLabel, Input as ChakraInput,
	InputProps as ChakraInputProps,
	Text,
	InputGroup,
	InputLeftAddon
} from '@chakra-ui/react'
import { FieldError } from 'react-hook-form'

import { forwardRef, ForwardRefRenderFunction } from 'react'
import { PopoverLabelInput } from '../PopoverLabelInput'

interface InputProps extends ChakraInputProps {
	name: string
	label?: string
	placeholder?: string
	error?: FieldError
	comments?: string
	prefix?: string
	popoverDescription?: string;
	popoverImage?: string;
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = ({ name, label, comments, prefix, error = null, popoverDescription, popoverImage, ...rest }, ref) => {
	return (
		<FormControl isInvalid={!!error}>
			{!!label &&
				<FormLabel
					w="100%"
					htmlFor={name}
					display="flex"
					alignItems="center"
					fontWeight="bold"
				>
					{label}
					{(!!popoverDescription || !!popoverImage) && (
						<PopoverLabelInput description={popoverDescription} image={popoverImage} />
					)}
				</FormLabel>
			}
			<InputGroup size="lg">
				{prefix && (
					<InputLeftAddon >
						{prefix}
					</InputLeftAddon>
				)}
				<ChakraInput
					fontSize={{ sm: 'md', md: 'lg' }}
					id={name}
					name={name}
					size="lg"
					variant="outline"
					ref={ref}
					{...rest}
				/>
			</InputGroup>
			{error &&
				<FormErrorMessage fontSize="xs">{error.message}</FormErrorMessage>
			}
			{comments && (
				<Text
					fontSize="sm"
				>
					{comments}
				</Text>
			)}
		</FormControl>
	)
}

export const Input = forwardRef(InputBase)
