import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	Text,
} from '@chakra-ui/react'

import {
	Select as ChakraReactSelect,
	Props as ChakraReactSelectProps
} from "chakra-react-select"

import { FieldError, Controller, Control, Merge, FieldErrorsImpl, FormProvider, useForm } from 'react-hook-form'

import { FC, useEffect, useState } from 'react'
import { Option } from '~/utils/Types/Global'

interface InputSelectMultProps extends ChakraReactSelectProps {
	name: string
	label?: string
	placeholder?: string
	error?: Merge<FieldError, FieldErrorsImpl<{
		label: string;
		value: NonNullable<string | number>;
	}>>
	options: Option[]
	control?: Control<any, any>
	comments?: string
}

const InputSelectMultBase: FC<InputSelectMultProps> = ({ name, label, placeholder, options, error = null, control, comments, ...rest }) => {
	const methods = useForm()
	const [domLoaded, setDomLoaded] = useState(false)

	useEffect(() => {
		setDomLoaded(true)
	}, [])

	if (!domLoaded) return null

	return (
		<FormProvider {...methods}>
			<Controller
				control={control}
				name={name}
				render={({
					field: { onChange, onBlur, value, name, ref },
					fieldState: { error }
				}) => (
					<FormControl isInvalid={!!error}>
						{!!label &&
							<FormLabel
								w="100%"
								htmlFor={name}
								display="flex"
								alignItems="center"
								fontWeight="bold"
							>
								{label}
							</FormLabel>
						}

						<ChakraReactSelect
							isMulti
							size="lg"
							name={name}
							onChange={onChange}
							onBlur={onBlur}
							value={value}
							options={domLoaded ? options : []}
							placeholder={placeholder}
							closeMenuOnSelect={false}
							noOptionsMessage={() => "Sem opções"}
							loadingMessage={() => "Carregando"}
							ref={ref}
							{...rest}
						/>
						{error &&
							<FormErrorMessage fontSize="xs">{error.message}</FormErrorMessage>
						}
						{comments && (
							<Text
								fontSize="sm"
							>
								{comments}
							</Text>
						)}
					</FormControl>
				)}
			/>
		</FormProvider>
	)
}

export const InputSelectMult = InputSelectMultBase
