import { Button, Image, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger } from "@chakra-ui/react"

type PopoverLabelInputProps = {
	description?: string;
	image?: string;
}

export function PopoverLabelInput({ description, image }: PopoverLabelInputProps) {
	return (
		<Popover
			placement="right"
			trigger="hover"
		>
			<PopoverTrigger>
				<Button
					variant="unstyled"
				>
					?
				</Button>
			</PopoverTrigger>
			<PopoverContent>
				<PopoverArrow />
				<PopoverBody>
					{description}
					{image && (
						<Image
							src={image}
							objectFit="contain"
						/>
					)}
				</PopoverBody>
			</PopoverContent>
		</Popover>
	)
}
