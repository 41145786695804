import {
	Box,
	Button,
	Flex,
	HStack,
	Icon,
	Text,
	useBreakpointValue,
	useMediaQuery,
	VStack,
} from "@chakra-ui/react";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { MdKeyboardArrowRight } from "react-icons/md";
import { InputSelectAsync } from "~/components/global/Form/InputSelectAsyncChackra";
import { api } from "~/services/apiClient";
import { Option } from "~/utils/Types/Global";
import { PostHomeProps } from "~/utils/Types/Home";
import { Title } from "../Title";
import { SlideShelf } from "./SlideShelf";
import { InputSelectMult } from "~/components/global/Form/InputSelectMult";
import { useShelfCategories } from "~/hooks/Admin/ShelfAndSolution/useShelfCategories";

type FormData = {
	section?: Option;
	category?: Option;
	categoryInput?: Option;
};

type ShelfAndSolution = {
	shelf?: PostHomeProps[];
	isFull?: boolean;
};

export function ShelfAndSolution({ shelf, isFull }: ShelfAndSolution) {
	const { control, watch } = useForm<FormData>();
	const isWideVersion = useBreakpointValue({
		base: false,
		lg: true,
	});
	const router = useRouter();
	const [isLargerThan1140] = useMediaQuery("(min-width: 1140px)");
	const [solutionType, setSolutionType] = useState<"bySection" | "assortment">(
		"bySection"
	);
	const [options, setOptions] = useState<Option>();
	const [categoryInput, setCategoryInput] = useState<Option>();
	const [loadingButton, setLoadingButton] = useState(false);
	const [searchCategories, setSearchCategories] = useState("");
	const sectionSelected = watch("section");

	const { data: categories, isLoading } = useShelfCategories({
		search: searchCategories,
		sectionSecureId: sectionSelected?.value as string,
	});

	const formatOptions = (data: any[]) => {
		const value: Option[] = data?.map((section: any) => ({
			label: section.title,
			value: section.secure_id,
		}));

		return value || [];
	};

	const handleSearchSections = async (search: string) => {
		const { data } = await api.get("v1/admin/list/sections", {
			params: {
				search,
			},
		});
		const value: Option[] = formatOptions(data);
		return value || [];
	};

	const handleSearchCategories = async (search: string) => {
		const { data } = await api.get("v1/solucao-sortimento-categories", {
			params: {
				search,
			},
		});

		const value: Option[] = formatOptions(data);
		return value || [];
	};

	const handleToCategory = () => {
		if (options) {
			const category = categories?.categories.filter(
				(e) => e.secure_id === options?.value
			);

			router.push(
				`solucaosortimento/${category ? category[0]?.section.slug : ""}/${category ? category[0]?.slug : ""
				}`
			);
		}
	};

	useEffect(() => {
		if (categoryInput) {
			const section = categories?.categories.filter(
				(e) => e.secure_id === categoryInput?.value
			);

			router.push(
				`solucaosortimento/${section ? section[0]?.section?.slug : ""}/${section ? section[0]?.slug : ""
				}`
			);
		}
	}, [categoryInput]);

	useEffect(() => {
		if (sectionSelected) {
			setOptions(formatOptions(categories?.categories || [])[0]);
		}
	}, [sectionSelected]);

	useEffect(() => {
		router.events.on("routeChangeStart", (url) => {
			setLoadingButton(true);
		});

		router.events.on("routeChangeComplete", (url) => {
			setLoadingButton(false);
		});

		router.events.on("routeChangeError", (url) => {
			setLoadingButton(false);
		});
	}, [router]);

	return (
		<VStack
			as="section"
			minHeight="65vh"
			background="#F0F0F0"
			paddingTop={isWideVersion ? "10" : "14"}
			paddingBottom={isLargerThan1140 ? "10" : "20"}
			paddingLeft="4"
			paddingRight="4"
		>
			<Flex
				w="100%"
				pl={isWideVersion ? 10 : 0}
				gap={isWideVersion ? "14.6rem" : "4rem"}
				flexDir={isLargerThan1140 ? "row" : "column"}
				maxWidth="1400px"
				sx={{
					"@media (max-width: 1335px)": {
						paddingRight: 10
					},
					"@media (max-width: 1139px)": {
						gap: "3rem",
						alignItems: "center",
						paddingRight: 0
					},
				}}
			>
				{shelf?.length ? (
					<VStack
						spacing="8"
						position="relative"
						sx={{
							"@media (max-width: 500px)": {
								width: "100%",
							},
						}}
					>
						<Title fontSize="1.8rem" text="Prateleira" textStyle="headerSM" as='h3' />
						<Flex w={isWideVersion ? "400px" : "100%"} justify="center">
							<Flex
								position={isLargerThan1140 ? "absolute" : undefined}
								width="100%"
								sx={{
									"@media (max-width: 500px)": {
										justifyContent: "center",
									},
								}}
							>
								<SlideShelf shelf={shelf} />
							</Flex>
						</Flex>
					</VStack>
				) : null}
				<Flex maxWidth="580px" flexDirection="column">
					<Title
						fontSize="1.8rem"
						text="Solução sortimento"
						textStyle="headerSM"
						as='h3'
					/>
					<Text marginTop="8" textStyle="textLG" color="blackAlpha.600">
						Navegue por todas as seções para obter informação sobre o
						desenvolvimento de categorias e sobre as marcas e fornecedores mais
						bem avaliados:
					</Text>
					<VStack
						maxWidth="540px"
						marginTop="14"
						align="flex-start"
						paddingLeft={isWideVersion && !isFull ? "40px" : undefined}
						spacing="6"
					>
						<HStack spacing="14" paddingLeft="3">
							<Text
								as="button"
								textTransform="uppercase"
								textStyle="headerMD"
								fontSize="1rem"
								borderBottom={
									solutionType === "bySection" ? "solid 2px #de0045" : undefined
								}
								onClick={() => setSolutionType("bySection")}
							>
								Por seção
							</Text>
							<Text
								as="button"
								fontSize="1rem"
								borderBottom={
									solutionType === "assortment"
										? "solid 2px #de0045"
										: undefined
								}
								textTransform="uppercase"
								textStyle="headerMD"
								onClick={() => setSolutionType("assortment")}
							>
								Categoria marca ou fornecedor
							</Text>
						</HStack>
						<VStack as="form" width="100%">
							{solutionType === "bySection" ? (
								<>
									<Box
										background="whiteAlpha.900"
										rounded="3xl"
										paddingX="5"
										width="100%"
									>
										<InputSelectAsync
											control={control}
											closeMenuOnSelect
											defaultOptions
											placeholder="Selecione a Seção"
											loadOptions={handleSearchSections}
											name="section"
											variant="unstyled"
										/>
									</Box>
									<Box
										background="whiteAlpha.900"
										rounded="3xl"
										paddingX="5"
										width="100%"
									>
										<InputSelectMult
											isMulti={false}
											isLoading={isLoading}
											control={control}
											value={
												!sectionSelected
													? []
													: options
														? options
														: formatOptions(categories?.categories || [])[0]
											}
											closeMenuOnSelect
											onChange={(e: any) => setOptions(e)}
											options={
												!sectionSelected
													? []
													: formatOptions(categories?.categories || [])
											}
											onInputChange={(e) => setSearchCategories(e)}
											name="category"
											placeholder="Selecione a Categoria"
											variant="unstyled"
										/>
									</Box>
									<Flex justify="flex-end" align="center" width="100%">
										<Button
											marginTop="6"
											padding="4"
											fontSize="md"
											background="#510C28"
											color="whiteAlpha.900"
											rounded="full"
											isLoading={loadingButton}
											size="lg"
											textTransform="uppercase"
											iconSpacing="6"
											onClick={handleToCategory}
											rightIcon={
												<Icon
													as={MdKeyboardArrowRight}
													background="#FFF"
													color="blackAlpha.900"
													rounded="full"
													fontSize="30px"
												/>
											}
											_hover={{
												background: "#510C28",
											}}
										>
											<Text mb="-0.35rem">Buscar</Text>
										</Button>
									</Flex>
								</>
							) : (
								<Box border="1px solid gray" borderRadius={20} px={2} w="100%">
									<InputSelectAsync
										control={control}
										openMenuOnClick={false}
										name="categoryInput"
										placeholder="Digite a categoria, marca ou fornecedor"
										variant="unstyled"
										loadOptions={handleSearchCategories}
										closeMenuOnSelect
										onChange={(e: any) => {
											setCategoryInput(e);
										}}
									/>
								</Box>
							)}
						</VStack>
					</VStack>
				</Flex>
			</Flex>
		</VStack>
	);
}
