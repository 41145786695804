import { Button, ButtonProps } from "@chakra-ui/react"
import { FC, ReactNode } from "react"

interface ButtonSubmitProps extends ButtonProps {
	children: ReactNode
}

export const ButtonSubmit: FC<ButtonSubmitProps> = ({ children, ...rest }) => {
	return (
		<Button
			type="submit"
			w="100%"
			// p="inherit"
			size="md"
			colorScheme='green'
			loadingText="Carregando"
			spinnerPlacement="end"
			{...rest}
		>
			{children}
		</Button>
	)
}
