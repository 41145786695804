import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	Select as ChakraSelect,
	SelectProps as ChakraSelectProps,
	Text
} from '@chakra-ui/react'
import { FieldError } from 'react-hook-form'

import { forwardRef, ForwardRefRenderFunction, useEffect, useState } from 'react'
import { Option } from '~/utils/Types/Global'
import { PopoverLabelInput } from '../PopoverLabelInput'

interface InputProps extends ChakraSelectProps {
	name: string
	label?: string
	placeholder?: string
	error?: FieldError
	options: Option[]
	popoverDescription?: string;
	popoverImage?: string;
}

const InputBase: ForwardRefRenderFunction<HTMLSelectElement, InputProps> = ({ name, label, options, error = null, popoverDescription, popoverImage, ...rest }, ref) => {
	const [domLoaded, setDomLoaded] = useState(false)

	useEffect(() => {
		setDomLoaded(true)
	}, [])

	if (!domLoaded) return null

	return (
		<FormControl isInvalid={!!error} w="inherit" width="100%">
			{!!label &&
				<FormLabel
					w="100%"
					htmlFor={name}
					display="flex"
					alignItems="center"
					fontWeight="bold"
				>
					{label}
					{(!!popoverDescription || !!popoverImage) && (
						<PopoverLabelInput description={popoverDescription} image={popoverImage} />
					)}
				</FormLabel>
			}
			<ChakraSelect
				fontSize={{ sm: 'md', md: 'lg' }}
				id={name}
				name={name}
				variant="outline"
				size="lg"
				lineHeight="2"
				ref={ref}
				{...rest}
			>
				{domLoaded && options.map(option => (
					<Text
						as="option"
						key={option.value}
						value={option.value}
						mb={-2}

					>
						{option.label}
					</Text>
				))}
			</ChakraSelect>
			{error &&
				<FormErrorMessage fontSize="xs">{error.message}</FormErrorMessage>
			}
		</FormControl>
	)
}

export const InputSelect = forwardRef(InputBase)
