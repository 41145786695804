import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";

import {
	AsyncSelect as ChakraReactAsyncSelect,
	AsyncProps as ChakraReactSelectAsyncProps,
	GroupBase,
} from "chakra-react-select";

import {
	Control,
	Controller,
	FieldError,
	FieldErrorsImpl,
	Merge,
} from "react-hook-form";

import { FC } from "react";
import { Option } from "~/utils/Types/Global";
import { PopoverLabelInput } from "../PopoverLabelInput";

interface InputSelectAsyncProps
	extends ChakraReactSelectAsyncProps<Option, boolean, GroupBase<Option>> {
	name: string;
	label?: string;
	placeholder?: string;
	inputInCard?: boolean;
	isMulti?: boolean;
	error?:
	| Merge<FieldError, (Merge<FieldError, FieldErrorsImpl<any>> | undefined)[]>
	| undefined;
	loadOptions?: (inputValue: any) => Promise<any>;
	control: Control<any, any>;
	textInputColor?: string;
	popoverDescription?: string;
	popoverImage?: string;
}

export const InputSelectAsync: FC<InputSelectAsyncProps> = ({
	name,
	label,
	placeholder,
	inputInCard = false,
	loadOptions,
	isMulti = false,
	error = null,
	control,
	textInputColor,
	popoverDescription,
	popoverImage,
	...rest
}) => {
	return (
		<Controller
			control={control}
			name={name}
			render={({
				field: { onChange, onBlur, value, name, ref },
				fieldState: { error },
			}) => (
				<FormControl isInvalid={!!error}>
					{!!label && (
						<FormLabel
							w="100%"
							htmlFor={name}
							display="flex"
							alignItems="center"
							fontWeight="bold"
						>
							{label}
							{(!!popoverDescription || !!popoverImage) && (
								<PopoverLabelInput description={popoverDescription} image={popoverImage} />
							)}
						</FormLabel>
					)}
					<ChakraReactAsyncSelect
						size="lg"
						name={name}
						onChange={onChange}
						onBlur={onBlur}
						value={value}
						placeholder={placeholder}
						closeMenuOnSelect={false}
						noOptionsMessage={() => "Sem opções"}
						loadingMessage={() => "Carregando"}
						ref={ref}
						loadOptions={loadOptions}
						isMulti={isMulti}
						{...rest}
					/>
					{error && (
						<FormErrorMessage fontSize="xs">{error.message}</FormErrorMessage>
					)}
				</FormControl>
			)}
		/>
	);
};
