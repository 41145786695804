import { MutableRefObject, useRef } from 'react'

export function useDebouncedPromise(fn: (...params: string[]) => Promise<void>, delay: number) {
	let timeoutRef: MutableRefObject<any> = useRef(null)

	function handler(...params: string[]) {
		return new Promise((resolve, reject) => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current)
			}

			timeoutRef.current = window.setTimeout(async () => {
				try {
					const response = await fn(...params)
					resolve(response)
				} catch (e) {
					reject(e)
				}
			}, delay)
		})
	}
	return handler
}
